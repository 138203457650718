import React, { useContext ,useState} from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const RegisterPage = () => {
  
  const BASE_URL = window.BASE_URL;
    const [userData, setUserData] = useState({
        name: '',
        phone: '',
        refer:''
    });
//   const myContext = useContext(AppContext);


const showError = (msg) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  
  const showSucess= (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }


  const sendOtp = () =>{
  
    axios.post(BASE_URL+ '/apiu/verify', {
      phone: userData.phone
    }, {
      params: {
        
      }
    }).then(({data})=> {
        
      // console.log(data);
      if(data.status){
           
        showSucess("Verification code sent to your phone!");
        localStorage.setItem('phone',userData.phone);
        navigate('/verify');

      }else{
        showError(data.msg);
      return;
      } 
    });
  }

  

const createAccount = () =>{
    if((userData.phone).length < 9){
        showError("Check your number!");
      return;
    }
    axios.post(BASE_URL+ '/apiu/account', { 
        
        name: userData.name,
        phone: userData.phone,
        refer: userData.refer 
    }, {
      params: {
         
      }
    }).then(({data})=> {
         
      if(data.status){
        showSucess(data.msg);
        sendOtp();
      }else{
        showError(data.msg);
      return;
      }
   
    });
  
  
  }

 const navigate= useNavigate();
 React.useEffect(() => {
    
  document.title = "Register";

  return () => {
    
  }
}, []);
  return (
    <>
    
    <Header/>
     
    <div className=" col-12 mx-auto p-3 g-0">
        <div className="card">
            <div className="bg-light text-dark text-capitalize card-header">register</div>
            <div className="card-body">
                <form className="mb-3" onSubmit={(e) =>{
                    e.preventDefault(); 
                    console.log(userData);
                    createAccount();
                }}>
                    <div className="vstack gap-4">
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">Full Name</label>
                            <input required="" type="text" className="form-control" onChange={e =>{
                                setUserData({...userData, name: e.target.value})
                            }} />
                        </div>
                        <div className="d-flex flex-column align-items-start">
                                <label className="text-capitalize form-label">phone number</label>
                                <input required="" type="tel" className="form-control"  onChange={e =>{
                                setUserData({...userData, phone: e.target.value})
                            }} />
                        </div>
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">refer code (optional)</label>
                            <input type="text" className="form-control"  onChange={e =>{
                                setUserData({...userData, refer: e.target.value})
                            }} />
                        </div>
                    <div>
                    <p stylexx="font-size: 0.8rem; text-align: start;">By Continuing, you agree to our <Link to="/terms">Legal Terms</Link> and you are 18 years or older.</p></div><button type="submit" disabled="" className="text-capitalize btn btn-primary">submit</button>
                    </div>
                </form>
                <p stylexx="font-size: 0.8rem;">Already have an account? <Link to="/login">Login</Link>
            </p>
            </div>
            </div>
            </div>

            <ToastContainer/>
    </>
  )
}

export default RegisterPage;