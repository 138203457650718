import React, { useContext ,useState} from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';
const LitePage = () => {
  
  const BASE_URL = window.BASE_URL;
   const myContext = useContext(AppContext);
   const { datas, dispatch ,isLogged, checkLogin} = myContext; 
   const [showView, setShowView] = useState(false);
   const [viewData, setviewData] = useState([]);

   React.useEffect(() => {
    
    document.title = "All Matches";

    checkLogin();
     return () => {
       
     }
   }, []);

   
   const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
   const [games, setGames] = useState([]);
   const [mygames, setMyGames] = useState([]);
   const navigate = useNavigate();

   
  const getChallenges = () =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.get(BASE_URL+ '/api/challenges', config).then(({data})=> {
       
      if(data.status){ 
        setGames(data.list);
      }else{ 
      }
    });


    axios.get(BASE_URL+ '/api/mymatches', config).then(({data})=> {
       
      if(data.status){ 
        setMyGames(data.list);
      }else{ 
      }
    });

  };
  const play = (el , data) =>{
    
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.get(BASE_URL+ '/api/play?i='+ data.ID, config).then(({data})=> {
       
      if(data.status){  
        checkLogin();
        navigate("/play/"+ data.id);
      }else{ 

        
        let text= el.target.innerText;
        el.target.innerText = data.msg;
        el.target.classList.add('btn-warning'); 
        setTimeout(() => {
          el.target.innerText = text;
          el.target.classList.remove('btn-warning');
        }, 3000);

      }
    });


  }
  React.useEffect(() => {
    getChallenges();
    checkLogin();
    return () => {
      
    }
  }, []);
  
 
  return (
    <>
    <div>
    <Header/>
    <div className="col-12 mx-auto p-3 g-0">
    <div className="d-flex flex-column">
      {mygames.length > 0 && (<>
        <div className="separator mt-3 mb-3">
          <img 
          src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}  alt="WinCupImg" style
          ={{height: '20px', width: '20px'}}/>
          &nbsp; Your Active Challenges &nbsp;
          <img src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}   alt="WinCupImg" 
          
          style={{height: '20px', width: '20px'}}/>
        </div>
        <ul id="my-running-challenge-list" className="px-2 m-0">
          {Object(mygames).map(i =>{ 
            return (<>
            <li className="list-group-item">
<div className="my-2 card">
<div className="d-flex align-items-center justify-content-between card-header">
<span className="text-capitalize">running challenge with</span>
<span className="text-success fw-bold">{i.title}</span></div>
<div className="d-flex align-items-center justify-content-between card-body">
<div className="d-flex align-items-center flex-grow-1">
<div className="d-flex align-items-center">
<div className="bg-dark rounded-circle me-2"    style={{height: '24px', width: '24px'}}>
<img src={`${process.env.PUBLIC_URL}/imgi/avatar-m-2.f630f4eeffb6e2e929909f66cfd814a2.svg`} alt="avatar"/></div>
<span className="fw-semibold text-truncate text-start" style={{  width: '100px'}} >{i.player1 != datas.login.data.username ? i.player1 : i.player2}</span></div></div>
<div className="d-flex align-items-center"><button onClick={() =>{
  if(i.gameStatus == 1){
    navigate('/play/'+i.ID);
    return;
  }
                
                setviewData(i);
                setShowView(true);
              }}  className="btn btn-success playChallange btn-sm">View</button></div></div></div></li>

 
        
        </>)
          })}
      
        </ul>
      
      </>)}
      <ul id="playable-challange-list" className="m-0 px-2">
        <div className="separator mt-3 mb-3">
          <img 
         src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`} alt="WinCupImg" style={{height: '20px', width: '20px'}}/>
          &nbsp; Challenges &nbsp;
          <img  src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}  alt="WinCupImg" 
          
          style={{height: '20px', width: '20px'}}/>
        </div>
         
          { 
            Object(games).map(i =>{
              return (<>
              
          <li className="p-0 overflow-hidden appear-from-left">
            <div className="my-2 card" stylex="border-color: gray;">
              <div className="d-flex align-items-center justify-content-between card-header">
                <span>Currently Playing: {i.playing}</span></div>
                <div className="d-flex align-items-center justify-content-between card-body">
                  <div className="d-flex align-items-center flex-grow-1">
                    <img 
                     src={`${process.env.PUBLIC_URL}/imgi/trophy.png`} 
  alt="avatar" 
                    style={{height: '30px', width: '30px'}} />
                    <span className="fw-semibold text-truncate text-success" stylex="width: 100px; font-size: 120%;" style={
                      {
                        width: '100px',
                        fontSize: '120%',
                      }
                    }> {" " +i.title}</span></div>
                    <div className="d-flex align-items-center">
                      <div className="hstack gap-2">
                      <button className="btn btn-primary playChallange btn-sm" onClick={(e) =>{ 
                          if( !( datas.login == null  || (datas.login.data.wallet) =='' )){
                            // return;
                          }
                          if(datas.login.data.wallet < i.amount){
                             
                          let text= e.target.innerText;
                          e.target.innerText = 'Low Balance';
                          e.target.classList.add('btn-warning');
                          // setGames([ {title: 'Sohag', playing:10, amount: 300, id:5} , ...games])
                          setTimeout(() => {
                            e.target.innerText = text;
                            e.target.classList.remove('btn-warning');
                          }, 1000);

                            return;
                          }
 
                          play(e ,i);
 
                        }}>Play</button>

                      </div>
                      </div></div></div>
          </li>


              </>);
            })
          }
        
                          </ul></div>

    </div>
    </div>

    {showView && (<><Offcanvas backdrop={true} className='h-50' show={showView} placement='bottom' onHide={() =>{
      setShowView(false);
    }} >
        <Offcanvas.Header closeButton className='text-black' closeVariant='black' >
          <Offcanvas.Title className="fw-bold offcanvas-title h5">
          <div className="text-capitalize d-flex flex-column align-items-start justify-content-start offcanvas-title h5"  style={{
                "font-size":"1.3rem"
              }} 
                 >
            <div><span   style={{
                "font-weight":"bold"
              }}   >room code:&nbsp;</span><span>{viewData.gcode}</span></div>
            <div><span style={{
                "font-weight":"bold"
              }}  >Your Result:&nbsp;</span><span>{viewData.gameStatus == 3 ? 'Cancel' : viewData.gameStatus }</span></div>
          </div>

</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="card"><div className="text-start card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {/* <div className="bg-dark rounded-circle me-2"  stylex="height: 24px; width: 24px;"><img src="/static/media/avatar-m-4.aa01f716288eeaae855f256e33323646.svg" alt="avatar"></div> */}
              <span className="fw-semibold text-truncate"   style={{
                width:"80px"
              }}>{viewData.player1}</span>
            </div>
              <div><img src={`${process.env.PUBLIC_URL}/imgi/vs.c153e22fa9dc9f58742d.webp`}  alt="verses-icon" width="24"/></div>
              <div className="d-flex flex-row-reverse align-items-center">
                {/* <div className="bg-dark rounded-circle ms-2"  stylex="height: 24px; width: 24px;"><img src="/static/media/avatar-m-2.f630f4eeffb6e2e929909f66cfd814a2.svg" alt="avatar"></div> */}
                <span className=" fw-semibold text-truncate"  style={{
                width:"80px"
              }}>{viewData.player2}</span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center pt-3"><span className="text-success fw-bold">{currency}{viewData.amount}</span></div>
            </div></div><hr/><p  cx="font-size: 0.8rem;">Your Game Result is Successfully Posted. Please allow us 2-5 minutes to review &amp; update your game. If you have Posted Wrong Result or Screenshot, kindly&nbsp;<Link to={'/support'}>Contact Support</Link></p>
 

        </Offcanvas.Body>
      </Offcanvas></>)}



    </>
  )
}

export default LitePage;