import React, { useContext } from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header';
import Accordion from 'react-bootstrap/Accordion';

import { FaInfoCircle,FaArrowLeft } from "react-icons/fa";
import { Link, Navigate, useNavigate } from 'react-router-dom';
const WalletPage = () => {
  
  
  const BASE_URL = window.BASE_URL;
  
  const navigate = useNavigate(); 
   const myContext = useContext(AppContext);
   const { datas ,checkLogin} = myContext;

   React.useEffect(() => {
    
    document.title = "My Wallet";

    checkLogin();
     return () => {
       
     }
   }, []);
   
   const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
    <div className="mb-3 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-start">
        <a href="#">
          <button className="btn btn-primary border" onClick={() =>{navigate(-1); }} >
            <FaArrowLeft/> <span className="text-capitalize">Back</span></button></a></div>
            <Link className="text-capitalize btn btn-outline-primary" to="/history">wallet history</Link></div>

    {datas.login !== null && !datas.login.data.isVerified  && (<>
            <div role="alert" className="fade d-flex align-items-center justify-content-between alert alert-danger show">
                <span><b>KYC Pending </b></span>
                <Link to="/kyc">
                    <button className="btn btn-danger btn-sm text-capitalize">complete here</button>
                </Link>
            </div>
            </>)}

     
    <div className="mb-3 shadow card">
      <div className="bg-light text-dark text-capitalize card-header">deposit chips</div>
    <div className="card-body"><div><div><div role="alert" className="fade alert alert-primary show" stylexx="font-size: 0.8rem;">
      
    These chips are Spin & Win and Buy chips, only games can be played with them. Can't withdraw in Bank or UPI
    
    </div><div className="d-flex align-items-center justify-content-center px-2"><div className="d-flex flex-column align-items-center justify-content-center"><span className="text-capitalize fw-bold" stylexx="font-size: 0.8rem;">chips</span>
    <span className="fs-4">{datas.login !== null && datas.login.data.wallet !==''  && (<>{currency}{datas.login.data.wallet}</>)}</span>
    </div></div>
    <div className="d-flex flex-column align-items-stretch pt-4">
      <Link className="text-decoration-none"  to="/buy">
        <div className="d-grid"><button className="btn btn-primary btn-lg text-capitalize mb-2">add</button></div>
        </Link>
        </div></div></div></div></div>

    <div className="shadow card">
      <div className="bg-light text-dark text-capitalize card-header">winning Money</div><div className="card-body"><div><div><div role="alert" className="fade alert alert-primary show" stylexx="font-size: 0.8rem;">
      These chips are won from game and earned from referral, they can be withdrawn in Bank or UPI. Games can also be played with these chips
       </div>
       <div className="d-flex align-items-center justify-content-center px-2">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span className="text-capitalize fw-bold" stylexx="font-size: 0.8rem;">Balance</span>
          <span className="fs-4">
            {datas.login !== null && datas.login.data.wallet2 !==''  && (<>{currency}{datas.login.data.wallet2}</>)}</span></div></div>
            <div className="d-flex flex-column align-items-stretch pt-4 pb-3">
              <Link className="text-decoration-none" to="/withdraw">
                <div className="d-grid">
                  <button className="btn btn-primary btn-lg text-capitalize">withdraw</button>
                  </div>
              </Link></div></div></div></div></div>

    <div className="container" stylexx="margin-bottom: 15px;"><ul className="list-group"></ul></div>


    </div>
    </>
  )
}

export default WalletPage;