import React, { useContext ,useState} from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header';
import Accordion from 'react-bootstrap/Accordion';


// import { FaInfoCircle,FaArrowLeft } from "react-icons/fa"; 
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const ReferPage = () => {
  
   const myContext = useContext(AppContext);
   const { datas ,checkLogin} = myContext;
   const showError = (msg) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      }
      );
  }
  
  const showSucess= (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  } 

  

   const copyToClipboard = (e) => {
    var textField = document.createElement('textarea');
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    showSucess("Copied to clipboard");
  }
  const [userReferCode, setuserReferCode] = useState( datas.login.data.username );

   React.useEffect(() => {
    
    document.title = "Refer";

    checkLogin();
     return () => {
       
     }
   }, []);
 
   const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
      
      <div><div className="mb-3 card">
        <div className="bg-light text-dark card-header">Your Referral Earnings</div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
              <span className="text-capitalize fw-bold" stylex="font-size: 0.8rem;">referred players</span>
              <span>{datas.login.data.totalRefer}</span>
            </div>
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <span className="text-capitalize fw-bold" stylex="font-size: 0.8rem;">Referral Earning</span>
          <span>{currency}{datas.login.data.referBallance}</span>
        </div></div>
        </div>
        </div>
        
        <div className="mb-3 card">
          <div className="bg-light text-dark card-header">Referral Code</div>
          <div className="card-body">
            <div><div>
              <img src={`${process.env.PUBLIC_URL}/imgi/refer.webp`}  alt="refer" className="w-75" />
            </div>
          <div><div><div>
            <div className="input-group">
              <input type="text" className="form-control p-2" disabled={true} value={userReferCode} />
                <button className="btn btn-primary text-uppercase" onClick={() =>{
                  copyToClipboard(userReferCode);

                }}>copy</button>
                </div></div></div>
              <p className="text-uppercase fw-bold fs-3 p-0 m-0 my-3">or</p>
              <div className="d-grid">
                <a href="whatsapp://send?text=Play Ludo and earn Rs10000 daily.%0ACommission Charge - 5% Only%0AReferral - 2% On All Games%0A24x7 Live Chat Support%0AInstant Withdrawal Via UPI/Bank%0ARegister Now, My refer code is 185755759.%0A👇👇%0Ahttps://ludoplayers.com/%23/register?refer=185755759">
                  <button className="btn btn-success btn-md w-100">
                    <span className="text-capitalize">share on whatsapp</span>
                    </button>
                  </a>
                </div>
                
                <div className="d-grid mt-2">
                  <button className="btn btn-secondary btn-md w-100" onClick={() =>{
                  copyToClipboard(userReferCode);

                }}><span className="text-capitalize">copy to clipboard</span></button>
                </div></div></div></div></div><div className="mb-3 card">
                  <div className="bg-light text-dark card-header">How It Works</div>
                  <div className="card-body">
                    <ul className="list-group">
                    <li className="list-group-item">You can refer and <b>Earn 2%</b> 
                    of your referral winning, every time</li>
                    <li className="list-group-item">Like if your player plays for <b>{currency}10000</b> and wins, You will get <b>{currency}200</b> as referral amount.</li>
                </ul></div></div></div>

    </div>
    <ToastContainer/>
    </>
  )
}

export default ReferPage;