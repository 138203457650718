import React, { useContext } from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';
import Accordion from 'react-bootstrap/Accordion';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
const NotFoundPage = () => {
  if(typeof(BASE_URL) == 'undefined'){
    const BASE_URL= '/';
    console.log(BASE_URL);
  } 
//   const myContext = useContext(AppContext);
 
React.useEffect(() => {
    
  document.title = "Not Found!";

  return () => {
    
  }
}, []);

  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
        <h1>not found</h1>
    </div>
    </>
  )
}

export default NotFoundPage;