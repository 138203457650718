import React, { useContext ,useState} from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';   
import axios from 'axios';
import MultiRangeSlider from '../../components/MultiRangeSlider';
const ChallengesPage = () => {
  
  const BASE_URL = window.BASE_URL;
   const myContext = useContext(AppContext);
   const { datas, dispatch ,isLogged, checkLogin} = myContext; 

   React.useEffect(() => {
    
    document.title = "All Matches";

    checkLogin();
     return () => {
       
     }
   }, []);

   
   const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';const [games, setGames] = useState([]);
   const [mygames, setMyGames] = useState([]);
   const [ranges, setRanges] = useState([600,900]);
   const [amount, setAmount] = useState('');
   const navigate = useNavigate();

   
  const getChallenges = () =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      
    }
    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.get(BASE_URL+ '/api/nchallenges', config).then(({data})=> {
       
      if(data.status){ 
        setGames(data.list);
      }else{ 
      }
    });


    axios.get(BASE_URL+ '/api/getMyChallenges', config).then(({data})=> {
       
      if(data.status){ 
        setMyGames(data.list);
      }else{ 
      }
    });

  };
  const play = (el , data) =>{
    
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.get(BASE_URL+ '/api/play?i='+ data.ID, config).then(({data})=> {
       
      if(data.status){  
        checkLogin();
        navigate("/play/"+ data.id);
      }else{ 

        
        let text= el.target.innerText;
        el.target.innerText = data.msg;
        el.target.classList.add('btn-warning'); 
        setTimeout(() => {
          el.target.innerText = text;
          el.target.classList.remove('btn-warning');
        }, 3000);

      }
    });


  }
  React.useEffect(() => {
    getChallenges();
    checkLogin();
    return () => {
      
    }
  }, []);
  
 
  return (
    <>
    <div>
    <Header/>
    <div className="col-12 mx-auto p-3 g-0">
   
    

    <div className="d-flex flex-column">
    <div className="bg-gray-200 h-100 w-100 p-3 bg-light d-flex align-items-center justify-content-between hstack gap-2">
      <div className="input-group flex-1 flex-nowrap">
        <input type="number" className="form-control" id="inputSetChallenge" name="amount" min="500" max="15000" placeholder="Amount" value={amount} onChange={e =>{
          setAmount(e.target.value);

        }}/>
        <button type="button" className="btn btn-primary w-25" id="setBt" onClick={() =>{
          if(500 > amount){
            navigate('/lite');
          }
        }} disabled={amount=='' ? true : false}>Set</button>
      </div>
    </div>

    <MultiRangeSlider
      min={500}
      max={15000} 
      maxCurrent={500}
      minCurrent={10000}
      onChange={({ min, max } ) =>{
         
      }
      }
    />

      {mygames.length > 0 && (<>
        <div className="separator mt-3 mb-3">
          <img 
          src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}  alt="WinCupImg" style={{height: '20px', width: '20px'}}/>
          &nbsp; Your Active Challenges &nbsp;
          <img src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}   alt="WinCupImg" 
          
          style={{height: '20px', width: '20px'}}/>
        </div>
        <ul id="my-running-challenge-list" className="px-2 m-0">
          {Object(mygames).map(i =>{ 
            return (<>
            <li className="list-group-item">
<div className="my-2 card">
<div className="d-flex align-items-center justify-content-between card-header">
<span className="text-capitalize">running challenge with</span>
<span className="text-success fw-bold">{i.title}</span></div>
<div className="d-flex align-items-center justify-content-between card-body">
<div className="d-flex align-items-center flex-grow-1">
<div className="d-flex align-items-center">
<div className="bg-dark rounded-circle me-2"    style={{height: '24px', width: '24px'}}>
<img src={`${process.env.PUBLIC_URL}/imgi/avatar-m-2.f630f4eeffb6e2e929909f66cfd814a2.svg`} alt="avatar"/></div>
<span className="fw-semibold text-truncate text-start" style={{  width: '100px'}} >{i.player1 != datas.login.data.username ? i.player1 : i.player2}</span></div></div>
<div className="d-flex align-items-center"><button onClick={() =>{
                navigate('/play/'+i.ID);
              }}  className="btn btn-success playChallange btn-sm">View</button></div></div></div></li>

 
        
        </>)
          })}
      
        </ul>
      
      </>)}
      <ul id="playable-challange-list" className="m-0 px-2">
        <div className="separator mt-3 mb-3">
          <img 
         src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`} alt="WinCupImg" style={{height: '20px', width: '20px'}}/>
          &nbsp; Challenges &nbsp;
          <img  src={`${process.env.PUBLIC_URL}/imgi/winner-cup-icon-png-19.png`}  alt="WinCupImg" 
          
          style={{height: '20px', width: '20px'}}/>
        </div>
         
          { 
            Object(games).map(i =>{
              return (<>
              
          <li className="p-0 overflow-hidden appear-from-left">
            <div className="my-2 card" stylex="border-color: gray;">
              <div className="d-flex align-items-center justify-content-between card-header">
                <span>Currently Playing: {i.playing}</span></div>
                <div className="d-flex align-items-center justify-content-between card-body">
                  <div className="d-flex align-items-center flex-grow-1">
                    <img 
                     src={`${process.env.PUBLIC_URL}/imgi/trophy.png`} 
  alt="avatar" 
                    style={{height: '30px', width: '30px'}} />
                    <span className="fw-semibold text-truncate text-success" stylex="width: 100px; font-size: 120%;" style={
                      {
                        width: '100px',
                        fontSize: '120%',
                      }
                    }> {" " +i.title}</span></div>
                    <div className="d-flex align-items-center">
                      <div className="hstack gap-2">
                        <button className="btn btn-primary playChallange btn-sm" onClick={(e) =>{ 
                          if( !( datas.login == null  || (datas.login.data.wallet) =='' )){
                            // return;
                          }
                          if(datas.login.data.wallet < i.amount){
                             
                          let text= e.target.innerText;
                          e.target.innerText = 'Low Balance';
                          e.target.classList.add('btn-warning');
                          // setGames([ {title: 'Sohag', playing:10, amount: 300, id:5} , ...games])
                          setTimeout(() => {
                            e.target.innerText = text;
                            e.target.classList.remove('btn-warning');
                          }, 1000);

                            return;
                          }
 
                          play(e ,i);
 
                        }}>Play</button>
                      </div>
                      </div></div></div>
          </li>


              </>);
            })
          }
        
                          </ul></div>

    </div>
    </div>
    </>
  )
}

export default ChallengesPage;