import React, { useContext } from 'react'; 
import AppContext from '../../context/AppContext';  
 
import { FaInfoCircle,FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Offcanvas } from 'react-bootstrap';
const PlayingPage = () => {
  
  const BASE_URL = window.BASE_URL;

  const props = useParams();
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const { datas ,checkLogin} = myContext;
  const [gameDatas, setGameDatas] = React.useState([]);
  const [image, setImage] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState('');
  const [rulesShow, setrulesShow] = React.useState(false);
  const [cancelShow, setcancelShow] = React.useState(false);
  const [winShow, setwinShow] = React.useState(false);
  const [cancelReason, setcancelReason] = React.useState('');

  const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
  const playAlert= datas.web !== null &&   datas.web.playAlert ?  datas.web.playAlert   : '';
 

  const showError = (msg) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      }
      );
  }
  
  
  const copyToClipboard = (e) => {
    var textField = document.createElement('textarea');
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    showSucess("Copied to clipboard");
  }

  const showSucess= (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }

  const getMatch = (id) =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      return;
    }
    
    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.post(BASE_URL+ '/api/geMatch', {
      "matchId":id,
    } , config).then(({data})=> { 
      console.log(data);

      if(data.status){
        setGameDatas(data.data);
      }else{
        showError(data.msg);
      }
    });


  }
   React.useEffect(() => {
    
    document.title = "Start Match";
    checkLogin();
 
    getMatch(props.id);
     return () => {
       
     }
   }, []);
 
 

  return (
    <>
    
    <Header/>
  
    <div className=" col-12 mx-auto p-3 g-0"> 

      {gameDatas.length ==0  ? (<>
    <p className='loadingGame'>Loading..</p>
    </>) :  (<>
    


    <div>
      <div className="d-flex alig-items-center justify-content-between mt-2 mb-3">
        <button type="button" className="text-capitalize btn btn-primary" onClick={() =>{
          navigate(-1);
        }}>
          <span className="text-capitalize"> <FaArrowLeft/> Back</span>
        </button>

        <div className="d-grid">
          <button type="button" className="d-flex align-items-center justify-content-center btn btn-outline-danger" onClick={ () =>{
            setrulesShow(!rulesShow)
          }}>
            <span className="text-capitalize">rules</span>
          </button>
        </div>
      </div>
      <p>
         
      </p>
      
      <div className="mb-3 shadow card">
        <div className="text-start card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column align-items-start vstack gap-2">
              {/* <div className="bg-dark rounded-circle me-2" styles="height: 24px; width: 24px;"><img src="/static/media/avatar-m-2.f630f4eeffb6e2e929909f66cfd814a2.svg" alt="avatar" /></div> */}
                <span className="fw-semibold text-truncate text-start" style={{
                width:'100px'
              }} >{gameDatas.player1}</span>
            </div>
            
            <div className="d-flex flex-column align-items-center vstack gap-2">
              <span><em><img src={`${process.env.PUBLIC_URL}/imgi/vs.c153e22fa9dc9f58742d.webp`}  alt="verses-icon" width="24"/></em></span>
              <span className="text-success fw-bold text-center">{currency}{gameDatas.amount}</span>
            </div>
            <div className="d-flex flex-column align-items-end vstack gap-2">
              {/* <div className="bg-dark rounded-circle" styles="height: 24px; width: 24px;"><img src="/static/media/avatar-f-1.5b811c8dcf47e4fec0d1e7faef90b43a.svg" alt="avatar"/></div> */}
              <span className=" fw-semibold text-truncate text-end" style={{
                width:'100px'
              }} 
              >{gameDatas.player2}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mb-2 shadow card">
        <div className="card-body" styles="font-size: 80%; color: red;"> {playAlert}</div>
      </div>
      
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">room code</div>
        <div className="card-body">
          <h1 className="py-3 fw-bold">{gameDatas.gcode}</h1>
          <div className="d-grid">
            <button className="btn btn-primary text-capitalize d-flex align-items-center justify-content-center" onClick={ () =>{
              copyToClipboard(gameDatas.gcode);
            }}>
              <span>copy code</span>
            </button>
          </div>

        <div className="d-grid mt-1">
          <button className="btn btn-danger text-capitalize d-flex align-items-center justify-content-center" onClick={ () =>{
            setrulesShow(!rulesShow)
          }}>Game Rules Updated</button>
          </div>
        </div>
        </div>
        
        
        <div className="mb-3 shadow card">
          <div className="bg-light text-dark text-capitalize card-header">game result</div>
          <div className="card-body">
            <p>After completion of your game, select the status of the game and post your screenshot below</p>
            <div className="d-flex flex-column align-content-stretch">
              <button className="btn btn-success btn-lg text-uppercase mb-3" onClick={() =>{
                setwinShow(true);
              }}>i won</button>
              <button className="btn btn-danger btn-lg text-uppercase mb-3" onClick={() =>{
                alert("I Lost!")
              }}>i lost</button>
              <button className="btn btn-outline-dark btn-lg text-uppercase" onClick={() =>{
                setcancelShow(true);
              }}>cancel</button>
            </div>
          </div>
        </div>
        
        <div className="card">
          <div className="card-header">Penalty</div>
          <div className="card-body">
            <table className="table table-striped table-bordered table-hover">
              <thead><tr><th>Amount</th><th>Reason</th></tr></thead>
              <tbody>
                <tr><td>₹100</td><td>Fraud / Fake Screenshot</td></tr>
                <tr><td>₹50</td><td>Wrong Update</td></tr>
                <tr><td>₹50</td><td>No Update</td></tr>
                <tr><td>₹25</td><td>Abusing</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

  
  </>)}

         
    </div>

    
    
    
    {winShow && (<><Offcanvas backdrop={true} className='h-75' show={winShow} placement='bottom' onHide={() =>{
      setwinShow(false);
    }} >
        <Offcanvas.Header closeButton className='text-black' closeVariant='black' >
          <Offcanvas.Title className="fw-bold offcanvas-title h5"> Upload Result
</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         
        <div class="pb-3 d-flex flex-column align-items-stretch">
          <div class="vstack gap-3">
            {/* <h1 class="text-capitalize">upload result</h1> */}
            <center>
              {imageUrl.length > 0 && (<>
              <img src={imageUrl} className='thumbnail'  style={{
                width:"350px",
                height:'auto'
              }}/>
              
              </>)}
            </center>
            <input type="file" hidden="1" style={{
              display:'none'
            }}
            onChange={e =>{

              if((e.target.files[0].type).includes('image') == false){ 
                showError("File type should be image");
                return;
              }
              setImage(e.target.files[0] );
              setImageUrl( URL.createObjectURL(e.target.files[0]));

            }} />
            <button type="button" class="btn btn-primary btn-lg" onClick={() =>{
              document.querySelector("[type='file']").click();
            }}>Upload Image</button>
            <button type="button" disabled={imageUrl.length > 0 ? false : true} class="btn btn-success btn-lg" onClick={() =>{
              alert("post")
            }}>Post Result</button>
            </div>
          </div>





        </Offcanvas.Body>
      </Offcanvas></>)}



    {cancelShow && (<><Offcanvas backdrop={true} className='h-50' show={cancelShow} placement='bottom' onHide={() =>{
      setcancelShow(false);
    }} >
        <Offcanvas.Header closeButton className='text-black' closeVariant='black' >
          <Offcanvas.Title className="fw-bold offcanvas-title h5"> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          
          <h5 className="text-capitalize">we would like to know more</h5>
          <h6 className="text-capitalize">select reason for cancelling</h6>
          <div className="row row-cols-auto g-2 py-3 container-fluid">
            <div className="col"><span className={ cancelReason != 'No Room Code' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'} onClick={(e) =>{setcancelReason(e.target.innerText);}}>No Room Code</span></div>
            <div className="col"><span className={ cancelReason != 'Not Joined' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Not Joined</span></div>
            <div className="col"><span className={ cancelReason != 'Not Playing' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Not Playing</span></div>
            <div className="col"><span className={ cancelReason != "Don't want to Play"? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Don't want to Play</span></div>
            <div className="col"><span className={ cancelReason != 'Opponent Abusing' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Opponent Abusing</span></div>
            <div className="col"><span className={ cancelReason != 'Game Not Start' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Game Not Start</span></div>
            <div className="col"><span className={ cancelReason != 'Other' ? "py-2 px-3 badge rounded-pill bg-secondary" : 'py-2 px-3 badge rounded-pill bg-primary'}  onClick={(e) =>{setcancelReason(e.target.innerText);}}>Other</span></div></div>
            <div className="d-flex flex-column align-items-stretch pb-3"><button type="button" className="text-capitalize btn btn-primary btn-lg" style={{
              opacity: cancelReason.length ==0 ? 0.7 : 1
            }} disabled={cancelReason.length ==0 ? true : false} onClick={()=>{
              alert(cancelReason)
            }}>Confirm</button></div>
       


        </Offcanvas.Body>
      </Offcanvas></>)}


    {rulesShow && (<><Offcanvas backdrop={true} className='h-75' show={rulesShow} placement='bottom' onHide={() =>{
      setrulesShow(false);
    }} >
        <Offcanvas.Header closeButton className='' closeVariant='black' >
          <Offcanvas.Title className="fw-bold offcanvas-title h5">Rules</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
             <ul className="list-group mb-3">
              <li className="list-group-item text-start">यदि गेम Join करने के बाद Opponent का एक भी टोकन ओपन हो जाता है और आप किसी भी कारण से तुरंत लेफ्ट करते है तो आपको सीधा <span stylex="color: red;">30% Loss</span> कर दिया जायेगा ! यदि आप जान भुजकर Autoexit करते है तो भी आपको 100% Loss कर दिया जायेगा ! यदि दोनों प्लेयर में किसी की काटी खुली नहीं तो उसे हम कैंसिल कर सकते है !</li>
              <li className="list-group-item text-start">यदि एक टोकन बाहर है और घर के पास है तो <span stylex="color: red;">30% Loss</span> दिया जायेगा लेकिन यदि गेम खेला गया है और 2 काटी बहार आयी हो तो गेम को लेफ्ट करने वाले को 100% Loss कर दिया जायेगा !</li>
              <li className="list-group-item text-start">Autoexit में यदि 1 टोकन बाहर है तो गेम कैंसिल किया जा सकता है लेकिन यदि आपने गेम जान भुजकर में छोड़ा होगा तो आपको Loss ही दिया जायेगा इसमें अंतिम निर्णय Admin का होगा !</li>
              <li className="list-group-item text-start">यदि आपको लगता है की Opponent ने जानभूझकर गेम को Autoexit में छोड़ा है लेकिन Admin ने कैंसिल कर दिया है तो आपसे वीडियो प्रूफ माँगा जायेगा इसलिए हर गेम को रिकॉर्ड करना जरुरी है ! यदि आप वीडियो प्रूफ नहीं देते है तो गेम रिजल्ट एडमिन के अनुसार ही अपडेट किया जायेगा चाहे आप विन हो या गेम कैंसिल हो !</li>
              <li className="list-group-item text-start">Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !</li>
              </ul>
              
              <ul className="list-group mb-3">
                <li className="list-group-item text-start">Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !</li>
                <li className="list-group-item text-start">दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा]</li>
                <li className="list-group-item text-start">'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा</li>
                <li className="list-group-item text-start">एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर !</li>
              </ul>

        </Offcanvas.Body>
      </Offcanvas></>)}

    <ToastContainer/>
    </>
  )
}

export default PlayingPage;