import React, { useContext } from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
const SupportPage = () => {
  if(typeof(BASE_URL) == 'undefined'){
    const BASE_URL= '/';
    console.log(BASE_URL);
  } 
  const myContext = useContext(AppContext);
 
  const { datas } = myContext;
React.useEffect(() => {
    
  document.title = "Support";

  return () => {
    
  }
}, []);
const instaUsername= datas.web !== null &&   datas.web.info.instagram ?  datas.web.info.instagram   : 'sohagsrz';
const emailName= datas.web !== null &&   datas.web.info.email ?  datas.web.info.email   : 'mdsohagislam25@gmail.com';
  return (
    <>
    
    <Header/>
      
<div className=" col-12 mx-auto p-3 g-0"><div className="d-flex flex-column align-items-stretch justify-content-start">
    <img  src={`${process.env.PUBLIC_URL}/imgi/support.webp`}    alt="support" className="w-75 mx-auto"/>
    <div className="d-flex flex-column align-items-stretch mb-3">
        <a href={"instagram://user?username="+instaUsername }
        className="text-decoration-none text-white w-100" 
        target="blank">
            <button 
            className="btn btn-lg text-white d-flex align-items-center justify-content-center w-100" 
            style={{
                background: 'linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%)'
            }} 
            ><div className="hstack gap-2">
 
<span className="text-capitalize">Connect on Instagram</span></div></button></a></div><div className="d-flex flex-column align-items-stretch">
  <a href={"mailto:"+emailName }className="text-decoration-none text-white w-100" target="blank"><button className="btn btn-outline-primary btn-lg d-flex align-items-center justify-content-center w-100"><div className="hstack gap-2">
   
   <span className="text-capitalize">Reach Us Via Email</span></div></button></a></div></div></div>
    </>
  )
}

export default SupportPage;