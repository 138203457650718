import React, { useContext, useState } from 'react'; 
import AppContext from '../context/AppContext';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
     FaInfoCircle,
     FaListUl,
     FaWallet,
     FaUserAlt,
     FaRegClock,
     FaDice,
     FaGift, 
     FaHeadset,
     FaUserCircle
    } from "react-icons/fa";
import { IoIosArrowForward,IoMdDocument } from "react-icons/io";
import '../style.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Header = () => {
    const myContext = useContext(AppContext);
    const { datas, dispatch ,isLogged,checkLogin} = myContext;
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
 
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const navigate = useNavigate(); 
    const videoGuide = datas.web !== null &&  datas.web.videos.videoHow.title  ?   datas.web.videos.videoHow.title   : '';
    const videoUrl = datas.web !== null &&   datas.web.videos.videoHow.video_url  ?   datas.web.videos.videoHow.video_url   : '';
    const logoUrl = datas.web !== null &&   datas.web.logoUrl  ?   datas.web.logoUrl  : '';
    const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';

  return (
    <>
    <div className="col-12" style={{
        position:'sticky',
        top:0,
        zIndex:1000
    }}  >
        {datas.web !== null && datas.web.topAlert && datas.web.topAlertMsg !=='' && (<>
        <div className="bg-danger py-2 text-white w-100">
            {datas.web.topAlertMsg}
        </div>
        </>)}
        <div className="w-100 bg-white shadow-sm text-white py-2 pe-2 ps-2 d-flex d-sm-flex align-items-center align-items-sm-center justify-content-between justify-content-sm-between" std="z-index: 100; height: 70px;"><div>
                <button variant="primary" onClick={handleShow} type="button" className="bg-white border-0 btn btn-light">
            <FaListUl/> </button>
                <Link className="text-decoration-none text-white fw-semibold fs-4" to="/"><span className="text-white">
                    
                    
                    <img 
                    src={logoUrl}
                    alt="logo" height="50"/>
                    </span></Link></div>

                    {isLogged() ? (<>
                        <Link to='/wallet' class="text-decoration-none text-white " >
                            <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
                                <FaWallet />
                                <strong>{datas.login !== null && datas.login.data.wallet !==''  && (<>&nbsp; {currency}{datas.login.data.wallet}</>)}</strong>
                                </div>
                        </Link>
    </>) : (<>
        <button  onClick={handleShow2} type="button" className="btn btn-outline-primary d-flex align-items-center justify-content-center">
                    <FaInfoCircle/>
                    <p className="m-0 p-0">&nbsp; Guide</p>
        </button>
    </>)}
                    

         </div></div>


{show2 && (<><Offcanvas backdrop={true} className='h-75' show={show2} placement='bottom' onHide={handleClose2} >
        <Offcanvas.Header closeButton className='bg-dark ' closeVariant='white' >
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{videoGuide}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            
        <div style={{
            position:'relative', 
        }} ><iframe 
        width="100%" 
        height="100%" 
        src={videoUrl}
        title="YouTube video player"
         frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" 
        style={{
            height: '45vh'
        }}
        ></iframe></div>
 
 
        </Offcanvas.Body>
      </Offcanvas></>)}
        

        {show && (<><Offcanvas show={show} onHide={handleClose}  scroll>
        <Offcanvas.Header className='bg-dark ' closeVariant='white' closeButton>
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{datas.web !== null &&   datas.web.appName ?   datas.web.appName  : 'LudoKing Players'}</Offcanvas.Title>
           </Offcanvas.Header>
        <Offcanvas.Body bsPrefix="d-flex flex-column align-items-stretch justify-content-start p-0 offcanvas-body">

        <div className="d-flex align-items-center justify-content-between p-4">
            <div className="fs-1 fw-bold text-start d-flex align-items-center justify-content-start">
                <div className="hstack gap-2">
                    <div className="m-0 me-1 d-flex align-items-center justify-content-start">
                        <p className="m-0">Hey, {datas.login !== null && datas.login.data.name !==''  && (<>{datas.login.data.name}</>)}</p>
                        <p className="text-truncate m-0 me-2" stylex="max-width: 125px;">&nbsp;</p>
                        <img  src={`${process.env.PUBLIC_URL}/imgi/hello.a512d06e9ef9c85276f6.webp`}  alt="hello icon" width="36px"/>
                    </div>
                </div>
            </div>
            {isLogged() && (<>
            <div>
                <Link  to={'/profile'}>
                    <div  >
                        <FaUserCircle class="rounded-circle bg-dark" style={
                        {
                            width:'40px',
                            height:'40px'
                        }
                    } />
                    </div>
            </Link>
        </div>
        </>)}


        </div>
        <div className="d-flex flex-column align-items-stretch justify-content-start">
        
        {isLogged() && (<>
            <Link to={'/'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3">
            <FaDice/> 
        <p className="p-0 m-0 text-capitalize">Play</p>
       </div></div> <IoIosArrowForward/>
        </Link>

            <Link to={'/profile'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3"> 
        <FaUserAlt/>
        <p className="p-0 m-0 text-capitalize">Profile</p>
        </div></div><IoIosArrowForward/>
        </Link>

        <Link to={'/wallet'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3"> 
        <FaWallet />
        <p className="p-0 m-0 text-capitalize">Wallet</p></div></div>
        <IoIosArrowForward/>
        </Link>


        <Link to={'/history'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3">
            <FaRegClock/>
        <p className="p-0 m-0 text-capitalize">History</p>
       
        </div></div> <IoIosArrowForward/>
        </Link>

        <Link to={'/refer'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3">
            <FaGift/> 
        <p className="p-0 m-0 text-capitalize">Refer & Earn</p>
        </div></div><IoIosArrowForward/>
        </Link>

        <Link onClick={() =>{ 
            let isCon = window.confirm("Are you sure to logout?");
            if(!isCon){
                return;
            }
            localStorage.removeItem('isLogged');
            localStorage.removeItem('hash');
            dispatch({ type: "login", login_data: null });
            navigate('/login'); 
            // checkLogin();
            
        }} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"  >
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3">
            {/* <FaGift/>  */}
        <p className="p-0 m-0 text-capitalize">Log out</p>
        </div></div><IoIosArrowForward/>
        </Link>



        </>)}
        

        {!isLogged() && (<>
            
        <Link to={'/login'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
        <div className="hstack gap-3">
             <FaDice/>
        <p className="p-0 m-0 text-capitalize">play</p></div></div>
       <IoIosArrowForward/>
        </Link>
        <Link to={'/register'} className="text-start text-decoration-none bg-white p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between" ><div className="d-flex align-items-center justify-content-start"><div className="hstack gap-3">
           
        <FaDice/>
            <p className="p-0 m-0 text-capitalize">register</p></div></div>
        
            <IoIosArrowForward/>
        </Link> 
        </>)}

        
        <Link to={'/support'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between" ><div className="d-flex align-items-center justify-content-start"><div className="hstack gap-3">
           <FaHeadset/>
           <p className="p-0 m-0 text-capitalize">support</p></div></div>
            <IoIosArrowForward/>
            </Link>
        <Link to={'/terms'} className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between" ><div className="d-flex align-items-center justify-content-start"><div className="hstack gap-3">
            <IoMdDocument/>
            <p className="p-0 m-0 text-capitalize">legal terms</p></div></div>
            <IoIosArrowForward/>
        </Link>

        
        </div>
 
        </Offcanvas.Body>
      </Offcanvas>
</>)}
       
    </>
  )
}

export default Header;