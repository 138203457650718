import React, { useContext, useEffect ,useState} from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import axios from 'axios';
 
import {  useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const VerifyPage = () => {  
  
  const BASE_URL = window.BASE_URL;
  const myContext = React.useContext(AppContext);
  const { datas, dispatch ,isLogged,checkLogin} = myContext;
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState('');
  const [wait, setWait] = useState(59);
 
const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
} 


useEffect(() => {
  if (wait > 0) {
    setTimeout(() => setWait(wait - 1), 1000);
  } else {
    // setWait(0);
  }

  return () => {
    
  }
}, [wait])


const resendBtn = () =>{
    
  setWait(60);
 
  let number = localStorage.getItem('phone');
  
  axios.post(BASE_URL+ '/apiu/verify', {
    phone: number
  }, {
    params: {
      
    }
  }).then(({data})=> {
       
    if(data.status){
         
      showSucess("Verification code sent to your phone!");
      localStorage.setItem('phone',number);
      navigate('/verify');
       
    }else{
      showError(data.msg);
    return;
    } 
  });


}
  
 

const verifyOtp = () =>{

  
  let number = localStorage.getItem('phone');
  
  if(number.length <= 0){ 
    navigate(-1);
    return;
  }
  axios.post(BASE_URL+ '/apiu/otpVerify', {
    phone: number,
    otp: otpCode
  }, {
    params: {
      
    }
  }).then(({data})=> {
      
    // console.log(data);
    if(data.status){ 
        localStorage.setItem('isLogged', true);
        localStorage.setItem('hash', data.data.session);
        dispatch({ type: "login", login_data: { isLogged: true, ...data} });
        showSucess("Successfully Logged In!");
        checkLogin();
        navigate('/');
    }else{
      showError(data.msg);


    return;
    }
     
  });



}

useEffect(() => { 
  
  document.title = "Verify OTP";
  // resendBtn();

  let number = localStorage.getItem('phone');
  if(number == '' || number == null ){
    navigate(-1);
  }
  return () => {
    
  }
}, []);


  return (
    <>
    
    <Header/>
     
<div className="card">
  <div className="bg-light text-dark text-capitalize card-header">Verify Phone number</div>
  <div className="card-body">
    <div className="d-flex align-items-center justify-content-start py-3">
      <button onClick={() =>{navigate(-1);}} className="btn btn-outline-primary btn-sm d-flex align-items-center justify-content-center">
 <span className="text-capitalize">change number</span>
</button></div>
<div><div className="py-6 justify-content-between mx-2" style={{
      display:'flex',
    }}>
  <div style={{
          flex: '1 1 0%',
          textAlign:'center'
        }}>
    <input aria-label="Please enter verification code."
     placeholder='Please enter verification code.' 
     autocomplete="off"  className="form-control" type="number" max={6} min={6} required="" value={otpCode} onChange={(e) =>
    {
      setOtpCode(e.target.value);
    }} style={{
          flex: '1 1 0%',
          textAlign:'center'
        }}/>
    <span>&nbsp;</span></div> 
    </div></div>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <span style={{fontSize: "0.8rem"}}>Resend in {wait} seconds</span>
                          <button className="btn btn-outline-dark btn-sm" disabled={wait <=0 ? false : true } onClick={() =>{
                            resendBtn();
                          }}>Resend OTP</button>
                          </div>
                          <div className="d-grid py-3">
                            <p  style={{fontSize: "0.8rem"}}>By Continuing, you agree to our 
                             <a href="#/terms">Legal Terms</a>  and you are 18 years or older.</p>
                            <button className="btn btn-primary text-uppercase" onClick={() =>{
                              // navigate('/ok/bye',{state : {pa:10,by:'Sohag'} });
                              verifyOtp();
                            }}>verify</button></div></div></div>
     <ToastContainer/>

    </>
  )
}

export default VerifyPage;