import React, { useContext, useState } from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LoginPage = () => {
  
  const BASE_URL = window.BASE_URL; 

const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
}



const [number, setNumber] = useState('');
const navigate = useNavigate();
const login = () =>{
  if(number.length < 9){  
    showSucess("Check Your Phone Number!");

    return;
  }
  axios.post(BASE_URL+ '/apiu/login', {
    phone: number
  }, {
    params: {
      
    }
  }).then(({data})=> {
      
    // console.log(data);
    if(data.status){
      sendOtp();
    }else{
      showError(data.msg);
    return;
    }
 
  });


}

const sendOtp = () =>{
    
 
  axios.post(BASE_URL+ '/apiu/verify', {
    phone: number
  }, {
    params: {
      
    }
  }).then(({data})=> {
       
    if(data.status){
         
      showSucess("Verification code sent to your phone!");
      localStorage.setItem('phone',number);
      navigate('/verify');
       
    }else{
      showError(data.msg);
    return;
    } 
  });


}
React.useEffect(() => {
  
  document.title = "Login";
  let number = localStorage.getItem('phone');
  if(number != '' || number != null ){
    setNumber(number);
  }

  return () => {
    
  }
}, []);

  return (
    <>
    <Header/>
     

    <div className=" col-12 mx-auto p-3 g-0">
      <div className="card">
        <div className="bg-light text-dark card-header">Login</div>
        <div className="card-body">
          <form onSubmit={e =>{
            e.preventDefault();
            login();
          }}>
            
          <label for="phone" className="w-100 text-start form-label">Mobile Number</label>
          <div className="input-group">
            <span className="input-group-text bg-light text-dark">
             <FaPhoneAlt />
              </span>
            <input value={number} onChange={(e) => setNumber(e.target.value)} type="tel" placeholder='Enter Your Phone' name="phone" id="phone" className="form-control" aria-describedby="phone" maxLength={13} minLength={13} patternx="/^[6-9]{1}[0-9]{13}$/"  />
            </div>
            
            <div className="d-grid py-3">
              <p stylecc="font-size: 0.8rem;">By Continuing, you agree to our <Link to="/terms">Legal Terms</Link> and you are 18 years or older.</p>
              <button className="btn btn-primary text-uppercase" type='submit' onClick={() =>{
                // login();
              }}>get otp</button>
              </div>
              
          </form>
              <div>
                <p stylecc="font-size: 0.9rem;">
                  Don't have an account? <Link to="/register">Register</Link>
                  </p></div>
              </div></div></div>

              <ToastContainer/>
    </>
  )
}

export default LoginPage;