import React, { useContext , useState} from 'react'; 
 
import AppContext from '../../context/AppContext';
import Header from '../../components/header'; 
  
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle,FaArrowLeft } from "react-icons/fa";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import axios from 'axios';
const WithdrawPage = () => { 
  
  const BASE_URL = window.BASE_URL;

   const myContext = useContext(AppContext); 
   const { datas ,checkLogin} = myContext;
 
   const minBuy = datas.web !== null && datas.web.minWithdraw  ?   datas.web.minWithdraw : 0;
   const maxBuy = datas.web !== null && datas.web.maxWithdraw  ?   datas.web.maxWithdraw : 0;

   const [show2, setShow2] = React.useState(false);
   const handleGuide = () => setShow2(!show2);
   const videoGuide = datas.web !== null &&   datas.web.videos.sell.title  ?   datas.web.videos.sell.title   : '';
   const videoUrl = datas.web !== null && datas.web.videos.sell.video_url  ?   datas.web.videos.sell.video_url   : '';
   const navigate = useNavigate();
   const showError = (msg) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      }
      );
  }
  
  const showSucess= (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }
  const [withdrawInfo, setWithdrawInfo] = useState({
    upiID: '',
    rUpiID: '',
    amount: 0,
    disabled: true
  });
  
  const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';

  const sendRequest = () =>{ 
   
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      return;
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.post(BASE_URL+ '/api/withdraw', {
      
        amount : withdrawInfo.amount, 
        account: {
          upiID:withdrawInfo.upiID,
          method:'UPI'
        }
    
    } , config).then(({data})=> {
       
      if(data.status){ 
        showSucess(data.msg); 
        setWithdrawInfo({
          upiID: '',
          rUpiID: '',
          amount: 0,
          disabled: true

        })
        checkLogin();
        setTimeout(() => {
          navigate('/history')
        }, 1000);
      }else{ 
 
        showError(data.msg);
      }
    });
  
    }
 
   React.useEffect(() => {
    
    document.title = "Sell Chips";

    checkLogin();
     return () => {
       
     }
   }, []);
 
 
  return (
    <>
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
    <div className="d-flex align-items-center justify-content-between mb-3">
    <div>
      <a onClick={() =>{
        navigate(-1);
      }}><button className="btn btn-primary text-center"> <FaArrowLeft/> <span className="text-capitalize">Back</span></button></a>
    </div>
    <div><button type="button" onClick={handleGuide} className="d-flex align-items-center btn btn-outline-primary btn-md"><span className="text-capitalize">guide</span></button>
    </div></div>
    <div className="mb-3 shadow card">
      <div className="bg-light text-dark card-header">Payment Mode</div>
      <div className="bg-secondary py-1">
        <span className="text-white" stylex="font-size: 0.8rem;">Withdrawal Money: {currency}{datas.login !== null && datas.login.data.wallet2 !==''  && (<>{datas.login.data.wallet2}</>)}</span>
      </div>
      <div className="card-body">
        <div className="d-flex flex-row align-items-center justify-content-between mb-1">
          <span stylex="font-size: 0.8rem;">Minimum: {currency}{minBuy}</span
          ><span stylex="font-size: 0.8rem;">Maximum: {currency}{maxBuy}</span>
          </div></div></div>
          <div className="mb-3 shadow card">
            <div className="bg-light text-dark card-header">Payment Details</div>
            <div className="card-body">
              <form className="" onSubmit={(e =>{
                e.preventDefault();
                if(datas.login.data.wallet2 < withdrawInfo.amount){
                  showError("Not have enough money, you can withdraw "+datas.login.data.wallet2 +currency+" !");
                  return;
                }
                if(maxBuy < withdrawInfo.amount){
                  showError("You cant withdraw more than "+maxBuy+"!");
                  return;
                }
                if(minBuy > withdrawInfo.amount){
                  showError("You cant withdraw less than "+minBuy+"!");
                  return;
                }
                if(withdrawInfo.upiID !== withdrawInfo.rUpiID){
                  showError("Upi ID Not Matched!");
                  return;
                }

                sendRequest();
                // showError("Wait");
                // console.log(withdrawInfo)
              })}>
                <div className="vstack gap-3">
                  <div>
                    <label className="text-capitalize text-start w-100 form-label" for="upiFormAccountNumber">UPI ID</label>
                    <input value={withdrawInfo.upiID} onChange={e=>{ 
                      if(withdrawInfo.upiID !=='' && withdrawInfo.rUpiID !=='' & withdrawInfo.amount !==''){
                        setWithdrawInfo({...withdrawInfo, disabled: false,upiID: e.target.value });
                       }else{
                        setWithdrawInfo({...withdrawInfo, disabled: true,upiID: e.target.value});
                       }

                    }} placeholder="Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormAccountNumber" className="form-control form-control" required />
                    </div>
                    <div>
                      <label className="text-capitalize text-start w-100 form-label" for="upiFormReEnterAccountNumber">Re Enter UPI ID</label>
                      <input onChange={e=>{ 
                      
                      if(withdrawInfo.upiID !=='' && withdrawInfo.rUpiID !=='' & withdrawInfo.amount !==''){
                        setWithdrawInfo({...withdrawInfo, disabled: false, rUpiID: e.target.value});
                       }else{
                        setWithdrawInfo({...withdrawInfo, disabled: true, rUpiID: e.target.value});
                       }
                    }} 
                    required
                    value={withdrawInfo.rUpiID} placeholder="Re Enter Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormReEnterAccountNumber" className="form-control form-control" /></div>
                      <div>
                        <label className="text-capitalize text-start w-100 form-label" for="upiFormChips">Amount</label>
                        <input required value={withdrawInfo.amount} onChange={e=>{ 
                      
                      if(withdrawInfo.upiID !=='' && withdrawInfo.rUpiID !=='' & withdrawInfo.amount !==''){
                        setWithdrawInfo({...withdrawInfo, disabled: false, amount: e.target.value});
                       }else{
                        setWithdrawInfo({...withdrawInfo, disabled: true, amount: e.target.value});
                       }
                    }}  placeholder="Amount to withdraw"
                     min={minBuy}
                      max={maxBuy}
                      type="number" id="upiFormChips" className="form-control form-control" /></div>
                        <p stylex="font-size: 0.8rem;">By Continuing, you agree to our <a href="#/terms">Legal Terms</a> and you are 18 years or older.</p>
                        <button type="submit" disabled={withdrawInfo.disabled} className="btn btn-primary">Submit</button>
                        </div>
               </form>
               </div>
               </div>
                    <div className="container" stylex="margin-bottom: 15px;"><ul className="list-group"></ul></div></div>
        
{show2 && (<><Offcanvas backdrop={true} className='h-75' show={show2} placement='bottom' onHide={handleGuide} >
        <Offcanvas.Header closeButton className='bg-dark ' closeVariant='white' >
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{videoGuide}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            
        <div style={{
            position:'relative', 
        }} ><iframe 
        width="100%" 
        height="100%" 
        src={videoUrl}
        title="YouTube video player"
         frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" 
        style={{
            height: '45vh'
        }}
        ></iframe></div>
 
 
        </Offcanvas.Body>
      </Offcanvas></>)}

      <ToastContainer/>
    </>
  )
}

export default WithdrawPage;