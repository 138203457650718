import React, { useContext } from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';
import Accordion from 'react-bootstrap/Accordion';
  
const TermsPage = () => {

  const myContext = useContext(AppContext);
 
 
  const { datas} = myContext;
  React.useEffect(() => {
    
    document.title = "Terms";
  
    return () => {
      
    }
  }, []);

  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
        
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Terms & Conditions</Accordion.Header>
        <Accordion.Body dangerouslySetInnerHTML={{__html: datas.web !== null ? datas.web.pages.terms :''}} />
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Cancellation & Refund Policy </Accordion.Header>
         
        <Accordion.Body dangerouslySetInnerHTML={{__html: datas.web !== null ? datas.web.pages.refunds :''}} />
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header> Privacy Policy </Accordion.Header>
         
        <Accordion.Body dangerouslySetInnerHTML={{__html: datas.web !== null ? datas.web.pages.privacy :''}} />
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header> About Us</Accordion.Header>
         
        <Accordion.Body dangerouslySetInnerHTML={{__html: datas.web !== null ? datas.web.pages.about :''}} />
      </Accordion.Item> 
      
      <Accordion.Item eventKey="4">
        <Accordion.Header> Contact us</Accordion.Header>
         
        <Accordion.Body dangerouslySetInnerHTML={{__html: datas.web !== null ? datas.web.pages.contact :''}} />
      </Accordion.Item>
    </Accordion>

    </div>
    </>
  )
}

export default TermsPage;