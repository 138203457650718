import React, { useContext ,useState } from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header';
import Accordion from 'react-bootstrap/Accordion';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const ProfilePage = () => {
  
  const BASE_URL = window.BASE_URL;
  const myContext = useContext(AppContext);
   const { datas ,checkLogin} = myContext;
   const [isEdit, setIsEdit] = useState(false);
   const [username, setUsername] = useState("");
   React.useEffect(() => {
    
    document.title = "Your Profile";
    checkLogin();
    if(datas.login !== null && datas.login.data.username !==''){
      setUsername(datas.login.data.username);
    }
    
     return () => {
       
     }
   }, []);
 
   
const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
} 

   const usernameApiSend = ( name ) =>{
    
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      return;
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.post(BASE_URL+ '/api/setUsername', {
      username: name
    } , config).then(({data})=> {
       
      if(data.status){ 
        showSucess(data.msg);
        setIsEdit(!isEdit);
        checkLogin();
      }else{ 
 
        showError(data.msg);
      }
    });


  }

  const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">

        

        <div class="mb-3 shadow card">
<div class="bg-light text-dark text-capitalize card-header">
profile</div><div class="card-body">
<div class="d-flex align-items-center justify-content-center">
<div stylex="
height: 80px; width: 80px;">
<div class="bg-success rounded-circle position-relative shadow" stylex="
width: 60px; height: 60px; background: url(&quot;/static/media/avatar-m-5.28bb00c89f61b95d81ebd66ceb9ed80f.svg&quot;);">
<div class="position-absolute shadow rounded-circle bg-white" stylex="
width: 24px; height: 24px; bottom: 0px; right: 0px; cursor: pointer;">
</div></div></div></div><div class="d-flex flex-column align-items-start justify-content-center mb-3">
<label class="form-label text-capitalize">
username</label><div class="align-self-stretch d-flex align-items-center">

<input type="text" class="form-control me-2" maxlength="10" disabled={!isEdit}
value={ username} onChange={e =>{
  setUsername(e.target.value);
}}/>
<button class="btn btn-primary text-capitalize btn-sm align-self-stretch" style={{width: 75}}
 onClick={ () =>{
  if(isEdit){
    usernameApiSend(username);
    return;
  }
  setIsEdit(!isEdit);
 }}>{isEdit ? "Save" : "Edit"}</button></div></div><div class="d-flex flex-column align-items-start justify-content-center mb-3">
<label class="form-label text-capitalize">
mobile number</label><div class="align-self-stretch">
<input type="number" class="form-control" readonly disabled 
value={datas.login !== null && datas.login.data.phone !=='' ? datas.login.data.phone :'' } />
</div></div>

{datas.login !== null && !datas.login.data.isVerified  && (<>
  <div class="d-flex flex-row align-items-center justify-content-between p-3 border-danger card bg-outline text-dark" style={{
    marginBottom:10
  }}>
<div class="d-flex flex-column align-items-start justify-content-center">
<span stylex="
font-size: 0.8rem;">
KYC status</span><span class="fw-bold text-capitalize">
pending </span></div>

<div>
  <Link to="/kyc">
<button class="btn btn-outline-danger btn-sm text-capitalize">complete KYC</button></Link>
</div>
</div>
</>)}

<div class="mb-3 shadow card">
  <div class="bg-light text-dark card-header">Metrics</div>
  <div class="card-body">
    <div class="g-0 gx-2 mb-2 row">
      <div class="col">
        <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
          <div class="text-capitalize text-start px-2 card-header" stylexx="
font-size: 0.9rem;">
  <div class="hstack gap-1">
    <span>games played</span>
    </div>
    </div>
  <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">
  {datas.login !== null && datas.login.data.playerInfo.totalBuy !==''  && (<>{datas.login.data.playerInfo.totalBuy}</>)}</div></div></div><div class="col"><div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
    <div class="text-capitalize text-start px-2 card-header" stylexx="font-size: 0.9rem;">
      <div class="hstack gap-1">
        <span>chips won</span>
        </div>
      </div>
      <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">{datas.login !== null && datas.login.data.playerInfo.totalWin !==''  && (<>{datas.login.data.playerInfo.totalWin}</>)}</div>
      </div></div></div>
      <div class="g-0 gx-2 row">
        <div class="col">
          <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
            <div class="text-capitalize text-start px-2 card-header" stylexx="font-size: 0.9rem;">
              <div class="hstack gap-1"><span>referral earning</span></div></div>
              <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">{datas.login !== null && datas.login.data.referBallance !==''  && (<>{datas.login.data.referBallance}</>)}</div></div></div><div class="col"><div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                <div class="text-capitalize text-start px-2 card-header" stylexx="font-size: 0.9rem;">
                  <div class="hstack gap-1"><span>Penalty</span></div>
                  </div>
                  <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">{datas.login !== null && datas.login.data.playerInfo.totalPenalty !==''  && (<>{datas.login.data.playerInfo.totalPenalty}</>)}</div></div></div></div></div></div>


</div>
</div>
    </div>
    <ToastContainer/>
    </>
  )
}

export default ProfilePage;