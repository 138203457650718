import React, { useContext } from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header'; 

import Offcanvas from 'react-bootstrap/Offcanvas'; 
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import { FaArrowLeft } from "react-icons/fa"; 
const KycPage = () => {
  const BASE_URL = window.BASE_URL;
   const myContext = useContext(AppContext);
   const { datas ,checkLogin} = myContext;
   const videoGuide = datas.web !== null &&   datas.web.videos.kyc.title  ?   datas.web.videos.kyc.title   : '';
   const videoUrl = datas.web !== null && datas.web.videos.kyc.video_url  ?   datas.web.videos.kyc.video_url   : '';
   


   const [show2, setShow2] = React.useState(false);
   const [details, setDetails] = React.useState({
    id: "",
    email: ""
   });
  
   
const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    }
    );
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
} 


  const sendVerifyKyc = () =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
  return;
}

let config = {
  headers:{
    "token":hash,
  }}; 
axios.post(BASE_URL+ '/api/kyc', {
  id: details.id,
  email: details.email
} , config).then(({data})=> {
   
  if(data.status){ 
    showSucess(data.msg); 
    checkLogin();
  }else{ 

    showError(data.msg);
  }
});

  }
   const handleGuide = () => setShow2(!show2);
   const navigate = useNavigate();
   React.useEffect(() => {
    
    document.title = "KYC";
    checkLogin();
     return () => {
       
     }
   }, []);

   if(datas.login !== null && datas.login.data.isVerificationPending){
    return <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
      
     <div class="d-flex flex-row align-items-center justify-content-between p-3 border-success card bg-outline text-dark" style={{
      marginBottom:10
    }}> 
  <span stylex="
  font-size: 0.8rem;">
  KYC status</span><span class="fw-bold text-capitalize">
  Verification Pending </span> 
  
  </div>
  <div>
    Please Wait for the verification!
  </div>

    </div>
    
    <ToastContainer/>
    </>
   }

   if(datas.login !== null && datas.login.data.isVerified){
    return <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
      
     <div class="d-flex flex-row align-items-center justify-content-between p-3 border-success card bg-outline text-dark" style={{
      marginBottom:10
    }}> 
  <span stylex="
  font-size: 0.8rem;">
  KYC status</span><span class="fw-bold text-capitalize">
  Verified </span> 
  
  </div>

    </div>
    
    <ToastContainer/>
    </>
   }
  

  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
        
        
    <div className="p-3">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <Link onClick={() =>{
            navigate(-1)
          }}><button className="btn btn-primary border">
            <FaArrowLeft/> <span className="text-capitalize">Back</span></button></Link>
        </div>
          <button type="button" className="d-flex align-items-center btn btn-outline-primary btn-md" onClick={handleGuide}><span className="text-capitalize">guide</span></button>
        </div>
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark card-header">KYC</div>
        <div className="card-body">
          <div className="form-group">
            <form onSubmit={(e) =>{
              e.preventDefault();
              console.log(details);
              console.log(details.id == '' || details.email == ''); 
              if(details.id == '' || details.email == ''){
                showError("Check The Details!");
                return;
              }
              sendVerifyKyc();
            }}> 
            <div className="mb-3 d-flex flex-column align-items-start">
              <label className="form-label" for="formBasicAadhar">Aadhar Number</label>
              <input value={details.id} onChange={e =>{
                setDetails({...details, id: e.target.value})
              }} placeholder="Enter Aadhar Card Number" maxlength="12" pattern="^([0-9]){12}$" required="" type="text" id="formBasicAadhar" className="form-control" />
                </div>
                <div className="mb-3 d-flex flex-column align-items-start">
                  <label className="form-label" for="formBasicEmail">Email Address</label>
                <input  value={details.email} onChange={e =>{
                setDetails({...details, email: e.target.value})
              }} 
              placeholder="Enter Email" type="email" id="formBasicEmail" className="form-control"/>
                </div>
                
                <p stylex="font-size: 0.8rem;">By Continuing, you agree to our <Link to={'/terms'}>Legal Terms</Link> and you are 18 years or older.</p>
              <div className="d-grid py-4">
                <button type="submit" disabled="" className="btn btn-primary">Request for KYC</button>
                </div></form>

          </div>
        </div>
      </div>
      <div>
        <p className="text-capitalize text-secondary">payments secured by</p>
        <div className="d-flex justify-content-center align-items-center">
          <div className="hstack gap-2">
            <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg" alt="gpay logo" width="48"/>
            <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg" alt="paytm logo" width="48"/>
            <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg" alt="phonepe logo" width="48"/>
            <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg" alt="upi logo" width="48"/>
            </div>
          </div>
      </div>
    </div>


    </div>

    
{show2 && (<><Offcanvas backdrop={true} className='h-75' show={show2} placement='bottom' onHide={handleGuide} >
        <Offcanvas.Header closeButton className='bg-dark ' closeVariant='white' >
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{videoGuide}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            
        <div style={{
            position:'relative', 
        }} ><iframe 
        width="100%" 
        height="100%" 
        src={videoUrl}
        title="YouTube video player"
         frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" 
        style={{
            height: '45vh'
        }}
        ></iframe></div>
 
 
        </Offcanvas.Body>
      </Offcanvas></>)}

      <ToastContainer/>
    </>
  )
}

export default KycPage;